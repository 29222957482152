import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  TimePicker,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { date } from "../../Helpers/HelperFunction";
import { allActions } from "../../Redux/myActions";
const { Option } = Select;
const { TextArea } = Input;

const AddAnnouncement = ({
  isAddAnnouncementModalVisible,
  setIsAddAnnouncementModalVisible,
  fetchAllAnnouncements,
  fetchActiveAnnouncements,
}) => {
  const actions = useDispatch();
  const [form] = Form.useForm();
  const addAnnouncementSubmitHandler = (value) => {
    console.log("from add announcement", value);
    const formData = {
      ...value,
      announcementStartDate: value.announcementStartDate
        ? date(value.announcementStartDate)
        : "",
      announcementEndDate: value.announcementEndDate
        ? date(value.announcementEndDate)
        : "",
    };

    console.log("From add announcement formdata", formData);
    actions(
      allActions(
        { ...formData },
        {
          method: "post",
          endPoint: `/announcement/add`,
          attempt: "ADD_ANNOUNCEMENT_REQUEST",
          success: "ADD_ANNOUNCEMENT_REQUEST_SUCCESS",
          failure: "ADD_ANNOUNCEMENT_REQUEST_FAILURE",
          //   navigateTo: null,
          successInternalState: (data) => {
            fetchAllAnnouncements();
            fetchActiveAnnouncements();
          },
          saveBearerToken: true,
        }
      )
    );
    // dispatch({ type: "ADD_ANNOUNCEMENT_REQUEST", payload: formData });
    form.resetFields();
    setIsAddAnnouncementModalVisible(false);
  };
  return (
    <Modal
      title="Add Announcement"
      open={isAddAnnouncementModalVisible}
      onCancel={() => {
        setIsAddAnnouncementModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addAnnouncementSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Announcement Text"
          name={"announcementText"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter announcement text!",
            },
          ]}
        >
          <TextArea placeholder="Write about announcement" />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Announcement Start Date"
            name={"announcementStartDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter announcement start date!",
              },
            ]}
          >
            <DatePicker
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>

          <Form.Item
            label="Announcement End Date"
            name={"announcementEndDate"}
            style={{ width: "100%" }}
            rules={[    
              {
                required: true,
                message: "Please enter announcement end date",
              },
            ]}
          >
            <DatePicker
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
        </div>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Announcement
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddAnnouncement;
